.analystNotesContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 1px);

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    }

    & > .has-tooltip {
      margin-left: auto;

      .iconContainer {
        width: 1em;
        height: 1em;
        cursor: pointer;
      }
    }

    &__add {
      display: flex;
      cursor: pointer;
      img {
        width: 14px;
        margin-right: 5px;
      }
    }
  }

  &__details {
    width: 100%;
    margin: 0.5em 0;

    &__paragraph {
      white-space: pre-wrap;
      &_content {
        word-break: break-word;
      }
    }
  }

  &__actions {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
  }
}
