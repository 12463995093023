@import "../../sass/colors.scss";

.detailLine {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 20px;
  &.is-array {
    .detailLine__item {
      flex-basis: 100%;
    }
  }

  .level-2 > & {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .detailLine-child:not(.is-array) {
    margin-left: 10px;
  }

  .detailLine-child.is-array > div:not(:last-child) {
    border-bottom: solid 1px $secondary-20-color;
    padding-bottom: 10px;
  }

  &:not(.detailLine-child) {
    margin-top: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 100%;

    p {
      overflow-wrap: anywhere;
    }

    &.level-1 {
      .detailLine__item__label {
        font-size: 16px;
      }
    }

    &.level-2 {
      .detailLine__item__label {
        font-size: 15px;
      }
    }

    &.level-3 {
      .detailLine__item__label {
        font-size: 14px;
      }
    }
  }
}
